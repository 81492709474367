import { ModelMixinTranslateServiceInstance } from '@app/locale.module';

/**
 * A translation tag to be passed through a translate pipe, if there's no translation value, the key will be displayed as is.
 */
export type TranslatableKey = string;

export const LOCALES = ['en_CA', 'fr_CA'] as const;
export type Locale = (typeof LOCALES)[number];

export const LOCALIZED_DECIMAL: Record<Locale, string> = { en_CA: '.', fr_CA: ',' };

/** @deprecated use `string` instead. */
export type TranslatableObject = {
    key: TranslatableKey;
    params?: {
        [key: string]: string | number;
    };
};

/** @deprecated use `TranslatableKey` instead. To reduce complexity, only strings should be used for translation.
 * See https://github.com/Humi-HR/interface/pull/8309#discussion_r1318907916
 */
export type Translatable = TranslatableObject | TranslatableKey;

/**
 * Narrowing, the process of refining types to more specific types...
 */
export function isTranslatableObject(obj: unknown): obj is TranslatableObject {
    if (obj === null || typeof obj !== 'object') {
        return false;
    }

    return 'key' in obj;
}

// Numbers sent to the BE need to be in the English format even if they are received in the French format
export function parseLocalizedNumber<IsNullable extends boolean = true>(
    number: string | number,
    locale: Locale = 'en_CA',
    nullable?: IsNullable
): IsNullable extends false ? number : number | undefined {
    if (typeof number === 'number') {
        return number;
    }

    if (nullable !== false && number === '') {
        return undefined as IsNullable extends false ? number : number | undefined;
    }

    // Other locales use other decimal separators, however, it needs to be converted to the English format before parsing through the Number function
    if (locale !== 'en_CA') {
        number = number.replace(LOCALIZED_DECIMAL[locale], LOCALIZED_DECIMAL.en_CA);
    }

    // Remove $, % and any commas or whitespace characters
    return Number(number.replace(/[\$\,\s\%]/g, ''));
}

// Numbers received from the BE are always in the English format, and will not always contain two decimal places
// This function ensures a value of 4 can be displayed as $4.00 or 4,00$ for the respective languages
export function formatLocalizedNumber(
    value: number | string | undefined | null,
    { locale, decimalPlaces }: { locale: Locale; decimalPlaces?: number } = { locale: 'en_CA' }
): string | undefined {
    if (value === undefined || value === null) {
        return undefined;
    }

    // Ensure value is a number
    const numericValue = typeof value !== 'number' ? Number(value) : value;

    // Handle NaN case
    if (isNaN(numericValue)) {
        return undefined;
    }

    const formattedValue =
        decimalPlaces && decimalPlaces > 0 ? numericValue.toFixed(decimalPlaces) : numericValue.toString();

    // toFixed will ensure there are two decimal places in the number
    // we then simply replace the decimal point in case the user is using a different language (ie. "." -> ",")
    return formattedValue.replace(LOCALIZED_DECIMAL.en_CA, LOCALIZED_DECIMAL[locale]);
}

/**
 * Gets a help centre article URL based on the user's language
 * @param articleNumber the id for the article within the URL (ie. '4409792231447')
 * @param locale the specific locale, if left off the function will attempt to retrieve the current locale from the TranslateService instance
 * @returns the fully formed help-centre URL (ie. https://support.humi.ca/hc/en-us/articles/4409792231447)
 */
export function getHelpCentreArticle(articleNumber: string, locale?: Locale): string {
    let currentLocale = locale;
    if (!locale) {
        currentLocale = (ModelMixinTranslateServiceInstance?.currentLang ?? 'en_CA') as Locale;
    }

    return `https://support.humi.ca/hc/${currentLocale === 'fr_CA' ? 'fr-ca' : 'en-us'}/articles/${articleNumber}`;
}
