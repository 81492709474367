import { stringToDate } from '@app/functions/string-to-date';
import { DefaultSaveOptions, SaveOptions } from '@app/interfaces/save-options.interface';
import { ModelMixinTranslateServiceInstance } from '@app/locale.module';
import { formatLocalizedNumber, Locale, parseLocalizedNumber } from '@app/types/translatable.type';
import { deserialize as DeserializerSync } from 'jsonapi-deserializer';
import moment from 'moment';
import { JSONApiStoreResponse, JSONApiUpdateResponse, Payload } from '../../interfaces';
import { ModelStatic } from './base-static.model';
import { ModelUnion } from './base.model';
import { QueryFetcher } from './query-fetcher.model';

export enum RowChangeStatusTypes {
    'saving_in_progress',
    'saved',
    'error_while_saving',
}

export function ModelMixin<T = any, IDType extends string | number = number>() {
    class Model extends ModelStatic<T>() {
        private _saveStatus: RowChangeStatusTypes | null;

        get createdAt(): any {
            return this._attributes['createdAt'] || this._attributes['created_at'];
        }

        get updatedAt(): any {
            return this._attributes['updatedAt'] || this._attributes['updated_at'];
        }

        get deletedAt(): any {
            return this._attributes['deletedAt'] || this._attributes['deleted_at'] || null;
        }

        /**
         * JSON:API spec required that `id` be a string
         * Our front end expects `id` to be a number
         * This allows our backend to send strings and our front end to use numbers
         */
        get id(): IDType {
            const id = this._attributes['id'];

            // we return null for invalid or missing id
            if (!id) {
                return null;
            }

            return isNaN(+id) ? id : Number(id);
        }

        /**
         * Used by the bulk selector to determine the id of the model
         *
         * Derived classes can define their own `bulkSelectId` getter
         * if they need to return a different id
         */
        get bulkSelectId(): IDType {
            return this.id;
        }

        /**
         * Attempts to pull the locale from the nx-translate service first (this is the most reliable! LocalStorage can be manipulated)
         * If it can't pull it from the service then it will default to LocalStorage and finally English if still it can't find anything
         *
         * This property is useful as often we have properties that change value based on the current language
         */
        get locale(): Locale {
            if (ModelMixinTranslateServiceInstance?.currentLang) {
                return ModelMixinTranslateServiceInstance.currentLang as Locale;
            }
            return (localStorage.getItem('locale') as Locale) ?? 'en_CA';
        }

        getLocalizedProperty(property: keyof T): string | undefined {
            return formatLocalizedNumber(this[property as keyof Model], { locale: this.locale });
        }

        setLocalizedProperty(property: keyof T, value: string): void {
            //@ts-expect-error There could be errors if the wrong key is sent via the property argument
            // we don't want to hide errors here because it means it was incorrectly used and this should be flagged for devs to fix
            this[property as keyof Model] = value === '' ? undefined : parseLocalizedNumber(value, this.locale);
        }

        /**
         * Used by the app-table to determine the status of the save operation.
         */
        get saveStatus(): RowChangeStatusTypes | null {
            return this._saveStatus;
        }

        get identifier(): string | null {
            const id = this._attributes['id'] ? this._attributes['id'].toString() : null;
            return id;
        }

        /**
         * Return a key:val of the underlying getters
         * used by the FormControl patchValue Object.keys()
         * which do not register getters
         */
        get formData(): any {
            const obj = {};
            for (const attribute in Object.getOwnPropertyDescriptors(this.constructor.prototype)) {
                if (Object.getOwnPropertyDescriptor(this.constructor.prototype, attribute)) {
                    if (
                        typeof Object.getOwnPropertyDescriptor(this.constructor.prototype, attribute)['get'] ===
                        'function'
                    ) {
                        switch (this.constructor['_version']) {
                            case 'v2':
                                obj[
                                    attribute.toString().replace(/(\-[a-z])/g, function ($1: string): string {
                                        return $1.toUpperCase().replace('-', '');
                                    })
                                ] = this[attribute];
                                break;
                            case 'v1':
                                obj[
                                    attribute.toString().replace(/([A-Z])/g, function ($1: string): string {
                                        return '_' + $1.toLowerCase();
                                    })
                                ] = this[attribute];
                                break;
                            default:
                                obj[attribute.toString()] = this[attribute];
                                break;
                        }
                    }
                }
            }
            return obj;
        }

        /**
         * Whether or not this model is in the database
         */
        get isPersisted(): boolean {
            return Boolean(this.id);
        }

        protected _attributes: any = {};
        protected _originalAttributes: any = {};
        protected _rawAttributes: any;
        protected _relationships: any = {};
        private _params: any = {};

        constructor(res?: object | object[]) {
            super();
            this.deserialize(res);
        }

        /**
         * Performs a GET request to the back-end without the need for a model
         * @param url the URL of the endpoint to hit
         * @returns A promise with the response body
         */
        static performGet(url: string): Promise<any> {
            return new QueryFetcher(null).httpGet(url);
        }

        /**
         * Performs a POST request to the back-end without the need for a model
         * @param url the URL of the endpoint to hit
         * @param body the body of the request
         * @returns A promise with the response body
         */
        static performPost(url: string, body?: any): Promise<any> {
            return new QueryFetcher(null).httpPost(url, body);
        }

        is(model: Model): boolean {
            const b = model;
            if (!b) {
                return false;
            }

            if (this.constructor !== b.constructor) {
                return false;
            }

            // In situation where both models are of the same class, but neither is saved,
            // we want to assume they aren't the same, since `id` is really what gives them identity.
            if (!this.isPersisted || !b.isPersisted) {
                return false;
            }

            return this.id === b.id;
        }

        isNot(model: Model): boolean {
            return !this.is(model);
        }

        attach(key: string, values: IDType | IDType[] | Model | Model[]): this {
            if (!('attach' in this._attributes) || this._attributes['attach'] === null) {
                this._attributes['attach'] = {};
            }

            if (!(key in this._attributes['attach'])) {
                this._attributes['attach'][key] = [];
            }

            this._attributes['attach'][key] = this._attributes['attach'][key].concat(this.extractIdsFromValues(values));

            return this;
        }

        clearAttach(): this {
            delete this._attributes['attach'];
            return this;
        }

        detach(key: string, values: number | number[]): this {
            if (!('detach' in this._attributes) || this._attributes['detach'] === null) {
                this._attributes['detach'] = {};
            }

            if (!(key in this._attributes['detach'])) {
                this._attributes['detach'][key] = [];
            }

            this._attributes['detach'][key] = this._attributes['detach'][key].concat(this.extractIdsFromValues(values));

            return this;
        }

        clearDetach(): this {
            delete this._attributes['detach'];
            return this;
        }

        /**
         * Whether or not this model is dirty (values have changed)
         * @return {boolean}
         */
        isDirty(): boolean {
            return JSON.stringify(this._attributes) !== JSON.stringify(this._originalAttributes);
        }

        isClean(): boolean {
            return !this.isDirty();
        }

        isFieldDirty(field: string): boolean {
            return this._attributes[field] !== this._originalAttributes[field];
        }

        getOriginalAttribute(field: string): any {
            return this._originalAttributes[field];
        }

        isFieldClean(field: string): boolean {
            return !this.isFieldDirty(field);
        }

        /**
         * Revert any attribute changes to the original value
         * deserialized upon instantiation
         */
        revert(): void {
            Object.assign(this._attributes, this._originalAttributes);
        }

        param(field: string, value: any): this {
            if (!this._params) {
                this._params = {};
            }
            this._params[field] = value;
            return this;
        }

        getParams(): any {
            return this._params;
        }

        getAttributes(): any {
            this._attributes = this.dateTimesToUtc(this._attributes);
            this._attributes = this.datesToDbFormat(this._attributes);
            return this._attributes;
        }

        getAttribute(name: string): any {
            return this._attributes[name];
        }

        hasAttribute(name: string): boolean {
            return this._attributes.hasOwnProperty(name);
        }

        getClass(): string {
            return this.constructor.name;
        }

        save(options: Partial<SaveOptions> = {}): Promise<this> {
            if (this.constructor['method']) {
                switch (this.constructor['method']) {
                    case 'PUT':
                        return this.update(options);
                }
            }

            if (this.id) {
                return this.update(options);
            }

            return this.create();
        }

        delete(): Promise<this> {
            return new Promise((resolve, reject) => {
                const fetcher = new QueryFetcher(this.constructor);
                fetcher
                    .httpDelete(
                        this.constructor['version'] +
                            '/' +
                            this.replaceUrlParams(this.constructor['resource']) +
                            '/' +
                            this.id
                    )
                    .then(() => resolve(this))
                    .catch((err) => reject(err));
            });
        }

        cleanUp(): void {
            this.clearAttach();
            this.clearDetach();
        }

        /**
         * Update the inner model values based on a key:val object
         * @param {any} values [description]
         */
        patchValue(values: any): void {
            Object.assign(this, values);
        }

        byString(s: string): any {
            let o = this;
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, ''); // strip a leading dot
            const a = s.split('.');
            for (let i = 0, n = a.length; i < n; ++i) {
                const k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        }

        sync<T extends Model>(relationshipKey: string, newSelection: T[]): Model {
            this.clearAttach().clearDetach();
            const newSelectionIds = newSelection.map((i) => i.id);

            const detach: number[] = this[relationshipKey]
                .filter((e: T) => !newSelectionIds.includes(e.id))
                .map((e: T) => e.id);

            const existingIds = this[relationshipKey].map((i) => i.id);

            const attach: IDType[] = newSelection.filter((e: T) => !existingIds.includes(e.id)).map((e: T) => e.id);

            this.attach(relationshipKey, attach).detach(relationshipKey, detach);
            return this;
        }

        create(): Promise<this> {
            this._saveStatus = RowChangeStatusTypes.saving_in_progress;
            return new Promise((resolve, reject) => {
                const payload = this.setPayload();
                const fetcher = new QueryFetcher(this.constructor);
                fetcher
                    .httpPost<JSONApiStoreResponse<T>>(
                        this.constructor['version'] + '/' + this.replaceUrlParams(this.constructor['resource']),
                        payload
                    )
                    .then((res) => {
                        this._saveStatus = RowChangeStatusTypes.saved;

                        this.deserialize(res);
                        resolve(this);
                    })
                    .catch((err) => {
                        this._saveStatus = RowChangeStatusTypes.error_while_saving;

                        reject(err);
                    });

                this.cleanUp();
            });
        }

        protected getDirty(): Payload {
            if (!this.isPersisted) {
                return this._attributes;
            }
            const payload: Payload = {};

            for (const key of Object.keys(this._attributes)) {
                if (this.isFieldDirty(key)) {
                    payload[key] = this._attributes[key];
                }
            }

            return payload;
        }

        /**
         * Convert Date objects to string format
         */
        protected stringsToDates(): void {
            for (const key in this._attributes) {
                if (
                    this._attributes[key] &&
                    typeof this._attributes[key] === 'object' &&
                    this._attributes[key]['timezone']
                ) {
                    this._attributes[key] = stringToDate(this._attributes[key]['date'], 'Y-MM-DD HH:mm:ss');
                }
            }
        }

        /**
         * One To Many Relationship
         */
        protected hasMany(modelClass: any, attribute: string, params?: any): any[] {
            if (!this._attributes[attribute]) {
                this._originalAttributes[attribute] = [];
                this._relationships[attribute] = [];
                this._attributes[attribute] = [];
            } else if (!this._relationships[attribute]) {
                this._relationships[attribute] = this._attributes[attribute].map((entity) =>
                    entity
                        ? new modelClass({
                              data: { attributes: entity, id: entity['id'] },
                              _params: params,
                          })
                        : null
                );
            }
            return this._relationships[attribute];
        }

        protected setMany<A extends ModelUnion>(attribute: string, val: A[]): void {
            this._relationships[attribute] = val;
            this._attributes[attribute] = val;
        }

        /**
         * One To One Relationship
         * @param  {any}    modelClass [description]
         * @param  {string} attribute  [description]
         * @return {any[]}             [description]
         */
        protected hasOne(modelClass: any, attribute: string, params?: any): any {
            if (!this._attributes[attribute]) {
                this._relationships[attribute] = null;
            } else if (!this._relationships[attribute]) {
                this._relationships[attribute] = new modelClass({
                    data: {
                        attributes: this._attributes[attribute],
                        id: this._attributes[attribute]['id'],
                    },
                    _params: params,
                });
            }
            return this._relationships[attribute];
        }

        protected setOne(attribute: string, model: ModelUnion | null, relationshipKey = ''): void {
            this._relationships[attribute] = model;
            this._attributes[attribute] = model;
            if (relationshipKey) {
                this[relationshipKey] = model?.id ?? null;
            }
        }

        /*
         * We have added edge cases for ids being strings
         */
        private extractIdsFromValues(
            values: IDType | IDType[] | number | string | string[] | number[] | Model | Model[]
        ): (string | number)[] {
            if (typeof values === 'number') {
                return [values];
            }

            if (typeof values === 'string') {
                return [Number(values)];
            }

            if (!Array.isArray(values)) {
                return [(values as Model).id];
            }

            if (typeof values[0] === 'number') {
                return values as number[];
            }

            if (typeof values[0] === 'string') {
                const stringValues = values as string[];

                return stringValues.map((v) => Number(v)) as number[];
            }

            return (values as Model[]).map((value: Model): IDType => value.id);
        }

        /**
         * Deserialize API response data and fill the model attributes
         * @param {object | object[]} res [description]
         */
        private deserialize(res?: any): void {
            if (typeof res === 'undefined') {
                this._attributes = {};
                return;
            }
            this._rawAttributes = res;
            if (this.constructor['version'] === 'v1' && !res.data) {
                this._attributes = res;
                this._params = res._params;
                this.storeOriginalAttributes();
                return;
            }
            if (this.constructor['version'] === 'v1' && res.data) {
                this._attributes = res.data ? DeserializerSync(<Document>res) : res;
                this._params = res._params;
                this.storeOriginalAttributes();
                return;
            }
            if (this.constructor['version'] === 'v2') {
                Object.assign(this._attributes, res.data ? DeserializerSync(<Document>res) : res);
                this._params = res._params;
            }
            this.stringsToDates();
            this._attributes = this.dateTimesToLocal(this._attributes);
            this.storeOriginalAttributes();
        }

        private storeOriginalAttributes(): void {
            this._originalAttributes = JSON.parse(JSON.stringify(this._attributes));
        }

        private replaceUrlParams(resource: string): string {
            for (const param in this._params) {
                resource = resource.replace(':' + param, this._params[param]);
            }

            if (resource.indexOf('/:') > -1) {
                throw new Error('All URL params must be replaced in ' + resource);
            }

            return resource;
        }

        private dateTimesToUtc(payload: any): any {
            const updatedPayload = JSON.parse(JSON.stringify(payload));
            for (const key of this.constructor['_datetimes']) {
                if (payload[key]) {
                    const utcDate = moment.utc(moment(payload[key]).valueOf()).format('YYYY-MM-DD HH:mm:ss');
                    updatedPayload[key] = utcDate;
                }
            }
            return updatedPayload;
        }

        private dateTimesToLocal(payload: any): any {
            const updatedPayload = payload;
            for (const key of this.constructor['_datetimes']) {
                if (payload[key]) {
                    const localDate = moment.utc(payload[key]).local().format('YYYY-MM-DD HH:mm:ss');
                    updatedPayload[key] = localDate;
                }
            }
            return updatedPayload;
        }

        private datesToDbFormat(payload: any): any {
            const updatedPayload = payload;
            for (const key of this.constructor['_dates']) {
                if (payload[key]) {
                    const localDate = moment(payload[key]).format('YYYY-MM-DD');
                    updatedPayload[key] = localDate;
                }
            }
            return updatedPayload;
        }

        private update(options: Partial<SaveOptions> = {}): Promise<this> {
            const { onlyDirty, includeId }: SaveOptions = { ...DefaultSaveOptions, ...options };

            this._saveStatus = RowChangeStatusTypes.saving_in_progress;

            return new Promise((resolve, reject) => {
                const payload = this.setPayload(onlyDirty);

                const payloadHasAttributes = Boolean(payload.data?.attributes);

                // return the current model if there is nothing to update
                if (!payloadHasAttributes) {
                    resolve(this);
                    this.cleanUp();
                    return;
                }

                const fetcher = new QueryFetcher(this.constructor);
                let baseUrl = this.constructor['version'] + '/' + this.replaceUrlParams(this.constructor['resource']);
                if (this.id && includeId) {
                    baseUrl += '/' + this.id;
                }
                fetcher
                    .httpPut<JSONApiUpdateResponse<T>>(baseUrl, payload)
                    .then((res) => {
                        this._saveStatus = RowChangeStatusTypes.saved;
                        /**
                         * If there's no included data, just update attributes from the response.
                         *
                         * This is a workaround since some endpoints don't return included data after updating a resource.
                         * Many of the payroll endpoints do and this check will keep the original behaviour that is intended
                         *
                         * The newer approach is to make our endpoints on update return fresh data with the proper included
                         * data for update.
                         */
                        if (!res?.included) {
                            this.storeOriginalAttributes();
                        } else {
                            // Otherwise, perform full deserialization for fresh data
                            this.deserialize(res);
                        }
                        resolve(this);
                    })
                    .catch((err) => {
                        this._saveStatus = RowChangeStatusTypes.error_while_saving;
                        reject(err);
                    });

                this.cleanUp();
            });
        }

        private setPayload(onlyDirty = false): any {
            const attributes = onlyDirty ? this.getDirty() : this._attributes;

            let payload = this.dateTimesToUtc(attributes);
            payload = this.datesToDbFormat(payload);
            switch (this.constructor['version']) {
                case 'v1':
                    return payload;
                default:
                    const serializer = this.constructor['serializer']();
                    return serializer.serialize(payload);
            }
        }
    }
    return Model;
}
