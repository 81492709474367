import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * A little hacky but this lets us access an Angular service outside of the typical injection context
 * This is necessary to allow our static models to get the current language from ngx-translate
 */
export let ModelMixinTranslateServiceInstance: TranslateService;

@NgModule()
export class LocaleModule {
    constructor(translateService: TranslateService) {
        ModelMixinTranslateServiceInstance = translateService;
    }
}
